import React, { useState } from 'react';
import CameraCapture from './CameraCapture'; // Assurez-vous que le chemin d'importation est correct

import {Button } from "react-bootstrap"; 

function ImageUploader({ uploadImageFunction }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [useCamera, setUseCamera] = useState(false);


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleCapture = (blob) => {
    const imgFile = new File([blob], "captured_image.jpg", { type: "image/jpeg", lastModified: new Date() });
    setSelectedImage(imgFile);
    setUseCamera(false); // Cachez la caméra après la capture
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      console.log("Aucune image sélectionnée");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedImage); // Assurez-vous que la clé correspond à ce que votre backend attend
      await uploadImageFunction(formData);
      console.log('Image bien uploadée');
      // Vous pouvez réinitialiser l'état ou gérer la réponse ici
      setSelectedImage(null); // Optionnel: Nettoyer après l'upload
    } catch (error) {
      console.error('Erreur lors de l\'upload de l\'image', error);
    }
  };

  return (
    <div>
        {useCamera ? (
            <CameraCapture onCapture={handleCapture} />
        ) : (
            <form onSubmit={handleSubmit}>
                <div>
                <input 
                        type="file" 
                        onChange={handleImageChange} 
                        accept="image/*" 
                        className='mt-2 mb-3'/>
                <br/>
                {selectedImage && (
                <img 
                  src={URL.createObjectURL(selectedImage)} 
                  alt="Aperçu" 
                  className='mb-3'
                  style={{ width: '100px', height: '100px' }} />
                )}
                </div>
                <div>
                    <Button variant="secondary" className='mb-3' onClick={() => setUseCamera(true)}>Utiliser la caméra</Button>
                </div>
                <div>
                    {selectedImage && (
                    <Button type="submit" className='mb-3'>Enregistrer</Button>
                    )}
                </div>
            </form>
        )}
    </div>
  );
}

export default ImageUploader;
