import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import RefMaintenanceCardService from "../../services/refmaintenancecard.service";
import { useLocation,useNavigate } from 'react-router-dom';


import { capitalizeFirstLetter} from "../../services/glossary.service";




const MaintenanceCard = () => {
  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
  const { product } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
  console.log("product reçu ",product);
  

  // Convertir prodName en minuscules
  const prodNameLowerCase = product.prodName.toLowerCase();

  const navigate = useNavigate();

  // Utilisation de useState pour stocker la liste des cartes de maintenance
  const [maintenanceCardList, setMaintenanceCardList] = useState([]);


  console.log('ID du produit sélectionné reçu:', product.id);
  
  
  const handleClick = (maintenanceCard) => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/entretien/fichedetail`, { state: { maintenanceCard } });
  };

  useEffect(() => {
    console.log('Dans useeffect **************');
    RefMaintenanceCardService.getMaintenanceCardByProduct(product.id).then(
      (response) => {
        setMaintenanceCardList(response.data);
       // console.log('les fiches récupérées:', response.data);
  
      },
      error => {
        console.error('Une erreur s\'est produite:', error);
      }
    );
  }, [product]);

  
  console.log('les fiches récupéré:', maintenanceCardList);
  
  return (
    <div className="d-flex row">
        <h1 className="align-text-center mb-3">Entretiens pour votre {prodNameLowerCase} </h1>
        {maintenanceCardList.map((maintenanceCard) => (  
          <div key={maintenanceCard.id} className="col-12 col-md-6 col-lg-6 mb-3" onClick={() => handleClick(maintenanceCard)}>
            <div className="row d-flex align-items-center gx-0 rectangle-style-white p-0">
                <div className="col p-0">
                    <img src={maintenanceCard.pict} alt={maintenanceCard.prodName} className="img-content"/>
                    </div>
                  <div className="col">
                      <span>{capitalizeFirstLetter(maintenanceCard.title)}</span>
                  </div>
              </div>
          </div>
        ))}
    </div>
  );
};

export default MaintenanceCard;