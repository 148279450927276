import axios from "axios";

import authHeader from './auth-header';

// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/`;

class PlanningService{


    getPlanning(){
        const user=JSON.parse(localStorage.getItem('user'));
        const userid=user.id;
        /*
        const req = {
          "userid":userid
        }
        */
        //localhost:8090/api/profil/applianceevent/listevent?userid=2&type=MAINTENANCE
        //console.log('getplanning sent ',req);
        let url=API_URL+"profil/event/list?userid="+userid;
        const headers=authHeader();
        console.log('Headers:', headers);
        //axios.get(url, { headers, params: req })
        //return axios.get(url,{ headers, pareq });
        // Effectuer la requête GET avec Axios
        return axios.get(url,{ headers: authHeader() });
    }


    savePlanning(title,dt_planned,note, type,idobjectlink) {
        const user=JSON.parse(localStorage.getItem('user'));
        const userid=user.id;
        
        const req = {
          "title":title,
          "dt_planned":dt_planned,
          "note":note,
          "type":type,
          "userid":userid,
          "idobjectlink":idobjectlink,
        }

        console.log('saveplanning sent ',req);
        let url=API_URL+"planning";
        return axios.post(url,req,{ headers: authHeader() });
    }

    
}


// Créez une instance 
const PlanningServiceInstance = new PlanningService();

// Exportez l'instance en tant qu'export par défaut
export default PlanningServiceInstance;


//export default new PlanningService();