import React, { useRef, useEffect, useState} from 'react';

import {Button } from "react-bootstrap"; 

function CameraCapture({ onCapture }) {
  const videoRef = useRef(null);
  const [streaming, setStreaming] = useState(null); // Ajoutez un état pour stocker le flux

/*
  useImperativeHandle(ref, () => ({
    stopCapture
  }));*/

  useEffect(() => {

    const videoCurrent = videoRef.current;


    navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } })
      .then(stream => {
        window.cameraStream = stream; //important maj global variable pour pouvoir cloturer la caméra à tout moment
        if (videoRef.current) videoRef.current.srcObject = stream;
        setStreaming(stream); // Stockez le flux pour une utilisation ultérieure
      })
      .catch(console.error);

    return () => {
      if (videoCurrent && videoCurrent.srcObject) {
        videoCurrent.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const stopMediaStream = (stream) => {
    stream.getTracks().forEach(track => track.stop());
  };

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(blob => {
      onCapture(blob);
      if (streaming) {
        stopMediaStream(streaming); // Arrêtez le flux après la capture
      }
    }, 'image/jpeg');
  };

  return (
    <div>
      <video 
      ref={videoRef} 
      autoPlay 
      playsInline
      style={{ width: '100%', height: 'auto' }}>  {/* Adaptation en largeur */}
      </video>
      <Button onClick={captureImage}>Capture</Button>
    </div>
  );
}

export default CameraCapture