import axios from 'axios';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/files/upload/tosearch`;

class UploadService {

    async uploadFile(formData){
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await axios.post(API_URL, formData, {
            headers: {
               // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + user.accessToken, 
                }
            });
        console.log(response);
        // Gérez la réponse de succès ici
        return response.data.fileUrl;
        } catch (error) {
            console.error(error);
            // Gérez l'erreur ici
        }
    }
}



// Créez une instance 
const UploadServiceInstance = new UploadService();

// Exportez l'instance en tant qu'export par défaut
export default UploadServiceInstance;

//export default new UploadService();