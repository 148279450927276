import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation,useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap"; 


import RefproductService from "../../services/refproduct.service";
import AuthService from "../../services/auth.service";

const ProductList = () => {
  
  const [productList, setProductList] = useState([]);
  // État pour stocker le terme de recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [voirPlus, setVoirPlus] = useState(false);

  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
  const { toadd } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer

  const navigate = useNavigate();
  

  const handleClicktoAdd = (product) => {
    //redirection vers la page d'ajout du produit
    if (toadd) navigate(`/inventaire/nouveau_2`, { state: { product } });
    //redirection vers la page des fiches de maintenance
    else navigate(`/entretien/fiche`, { state: { product } });
  };

  // Gestionnaire d'événements pour mettre à jour le terme de recherche
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setVoirPlus(false); // Réinitialiser voirPlus à chaque nouvelle recherche
  };

  // Gestionnaire d'événements pour mettre à jour le terme de recherche
  const handleVoirPlus = (bool) => {
    if (bool) setSearchTerm('');
    setVoirPlus(bool); // Réinitialiser voirPlus à chaque nouvelle recherche
  };

  // Filtrer la liste des produits basée sur le terme de recherche
  const filteredProducts = productList.filter((product) => {
    return product.prodName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Déterminer les produits à afficher en fonction de voirPlus et searchTerm
  const produitsAAfficher = voirPlus || searchTerm ? filteredProducts : productList.slice(0, 5);


  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    

    console.log('Dans inventaire des produits');
   
    
    RefproductService.getAllProduct().then((response) => {
      setProductList(response.data);
    })
    .catch((error) => {
      console.error('Une erreur s\'est produite:', error);
    });
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  
  return (
    <div  className="product-container"> 
          <h1 className="text-left mt-3 mb-3">Choisir le produit</h1>

        {/* Champ de recherche */}
      <div className="search-bar mb-4">
        <input
          type="text"
          placeholder="Rechercher un produit..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
        />
      </div>


         <div className="d-flex row gx-3"> 
          {produitsAAfficher.map((product) => (
              <div key={product.id} className="col-4 col-md-3 col-lg-3 product-link mb-3" onClick={() => handleClicktoAdd(product)}>
                   <div className="product-background text-center ">
                    <img src={product.pict} alt={product.prodName} className="img-fluid mb-3"/>
                    <span className="fw-bold">{product.prodName}</span>
                    </div>
              </div>
          ))}
        </div>
        {(!voirPlus) && (
        <Button variant="secondary" className="btn btn-primary mb-10" onClick={() => handleVoirPlus(true)}>Voir plus</Button>
        )}
        {(voirPlus) && (
        <Button variant="secondary" className="btn btn-primary mb-10" onClick={() => handleVoirPlus(false)}>Voir Moins</Button>
        )}
        <div>
          <br/><br/><br/><br/><br/>
        </div>
    </div>
  );
};

export default ProductList;

