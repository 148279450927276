import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import EventBus from "../../common/EventBus";
import {  Button } from "react-bootstrap"; 
import ApplianceService from "../../services/appliance.service";
import AuthService from "../../services/auth.service";
import { useNavigate } from 'react-router-dom';

const ApplianceList = () => {
   const [productList, setProductList] = useState([]);
  
   const navigate = useNavigate();
  

  const handleClicktoDetail = (appliance) => {
     navigate(`/inventaire/fichedetail`, { state: { appliance } });
  };

  const handleClicktoAdd = (appliance) => {
    const toadd=true;
    navigate(`/productlist`,{ state: { toadd } });
  };

  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    

    console.log('Dans inventaire des produits');
   

    ApplianceService.getApplianceByUser(user.id)
      .then((response) => {
        setProductList(response.data);
      })
      .catch((error) => {
        console.error('Une erreur s\'est produite:', error);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });
    

    
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  
  return (
    <div className="product-container justify-content-center"> 
         <div className="col-12 mx-auto"> 
          <h1 className="text-left mt-3 mb-1">Mon inventaire</h1>
          <div className="underline mb-4"></div>
          </div>
         {/* Intégration du SwitchButton */}
         
         <div className="d-flex row gx-3 mb-3"> 
          {productList.map((appliance) => (
              <div key={appliance.id} className="col-4 col-md-3 col-lg-3 mb-4" onClick={() => handleClicktoDetail(appliance)}>
                  <div className="product-background">
                    <img src={appliance.pict} alt={appliance.prod_name} className="img-fluid"/>
                    <div className="gray-line"></div>
                    <span className="mt-3 ">{appliance.name}</span>
                    </div>
                 
              </div>
          ))}
        </div>
        <div className="col-12 col-md-4 col-lg-4 mb-4" onClick={() => handleClicktoAdd()}> 
             <Button variant="primary">Ajouter un appareil</Button>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
  );
};

export default ApplianceList;