// config.js

console.log('SERVER_IP CONFIG DOCKER'); // Cette ligne affiche la valeur de SERVER_IP dans la console

const config = {
    
    serverIP: "https://dcareapp.fr",
    
    };
  
  export default config;
  