import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/`;

class EventService{


    getEvent(type,idobjectlink){
        const user=JSON.parse(localStorage.getItem('user'));
        const userid=user.id;
        
        const req = {
          "userid":userid
        }

        console.log('getEvent sent ',req);
        let url=API_URL+"getEvent";

        const headers=authHeader();
        console.log('Headers:', headers);
        //axios.get(url, { headers, params: req })
        //return axios.get(url,{ headers, pareq });
        // Effectuer la requête GET avec Axios
        return axios.get(url, { headers, params: req })
    }


    saveEvent(title,note, type,idobjectlink) {
        const user=JSON.parse(localStorage.getItem('user'));
        const userid=user.id;
        const currentDate = new Date();
        const req = {
          "title":title,
          "dt_creation":currentDate,
          "dt_realisation":currentDate, 
          "note":note,
          "type":type,
          "status":"DONE",
          "userid":userid,
          "idobjectlink":idobjectlink,
        }

        console.log('saveEvent sent ',req);
        let url=API_URL+"event";
        return axios.post(url,req,{ headers: authHeader() });
    }

    //-1 for null value
    saveMaintenanceEvent(dt_creation,dt_planned,title, note, applianceId,maintenanceCardId) {
      let url=API_URL+"profil/event/new";

      const user=JSON.parse(localStorage.getItem('user'));
      const userid=user.id;
  
      const req = {
        "title":title,
        "dt_creation":dt_creation,
        "dt_realisation":dt_planned,
        "note":note,
        "type":"MAINTENANCE",
        "status":"NEW",
        "userId":userid,
        "applianceId":applianceId,
        "maintenanceCardId":maintenanceCardId
      }

      console.log('saveevent sent ',req);
    
      return axios.post(url,req,{ headers: authHeader() });
  }
    
}


// Créez une instance de NotifService
const EventServiceInstance = new EventService();

// Exportez l'instance en tant qu'export par défaut
export default EventServiceInstance;


//export default new EventService();