import React, { useEffect, useState,useRef } from "react";
import { Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getLabel from "../../services/glossary.service";
import Calendar from 'react-calendar';
import "../../css/calendar.css";
import eventService from "../../services/event.service";
import Icon  from "../common/Icon";




const MaintenanceCardDetail = () => {
  const location = useLocation();
  const { state } = location;
  const { maintenanceCard } = state;


  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPlanning, setShowModalPlanning] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // État pour stocker l'index de la diapositive actuellement affichée
  const [selectedDate, setSelectedDate] = useState(new Date());
  const sliderRef = useRef(null);

  const [textInput, setTextInput] = useState('');

    const handleInputChange = (e) => {
        setTextInput(e.target.value);
    };


  /*handle modal close for all modal*/  
  const handleClose = () => {
    setShowModal(false);
    setShowModalPlanning(false);
  };

  const handleShow = () => {
    setShowModal(true);
    setShowModalPlanning(false);
  };

  const handleShowPlanning = () => {
    setShowModal(false);
    setShowModalPlanning(true);
  };
  const handleShowFinish = () => {};

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSaveEvent = (dt_planned) => {
    const currentDate = new Date();
    //applianceId inconnu -1
    eventService.saveMaintenanceEvent(currentDate,dt_planned,maintenanceCard.title,textInput,-1,maintenanceCard.id);
    setShowModal(false);
    setShowModalPlanning(false);
  };


  
    useEffect(() => {
    console.log('Dans useEffect CardDetail:');
    
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992); // Vous pouvez ajuster ce point de rupture selon vos besoins
    };

    handleResize(); // Appeler la fonction initiale pour définir l'état initial

    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!state || !state.maintenanceCard) {
    // Gérer le cas où state ou maintenanceCard n'est pas défini
    return <div>Erreur : Aucune donnée de maintenanceCard trouvée</div>;
  }
  

  

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Masquer les flèches
    afterChange: (index) => {
      setCurrentSlideIndex(index); // Mettre à jour l'index de la diapositive actuellement affichée
    },
  };

  console.log('Carte reçue:', maintenanceCard);
  // Supposons que maintenanceCard.pict contient la chaîne d'URLs séparées par des points-virgules
  //const imageLinks = maintenanceCard.pict.split(';');

  return (
    <div className="product-container">
        <h1 className="mb-3">{maintenanceCard.title}</h1>
        <div className='row'>
            <div className={`col-12 col-md-6 col-lg-6 mb-3 ${isLargeScreen ? '' : ''}` }>
              <div className="fiche d-flex justify-content-between align-items-center pt-2 pb-2 px-3 h-100">
                  <div className="col-2">
                      <Icon src={'clock.svg'}  alt="Clock Icon" className=""/>
                  </div>
                  <div className="col d-flex justify-content-between ">
                        <span className="mr-2">Durée</span>
                      <span>{maintenanceCard.duration} minutes</span>
                      </div>
                </div>
              </div>
                <div className="col-12">  
                  <div className="d-flex justify-content-between align-items-center pt-2 pb-2 h-100">
                        <div className="col d-flex">
                            <span>Fréquence :</span>
                            <div>&nbsp;{maintenanceCard.frequency} jours</div>
                        </div>
                        <div className="col d-flex justify-content-end">
                            <span>Difficulté :</span>
                            <div>&nbsp;{getLabel(maintenanceCard.level)}</div>
                        </div>
                    </div>
                  </div>  
        </div>
            
        <div className='row'>
          <div className={`d-flex justify-content-between align-items-end mb-3 mt-3 col-12 col-lg-6 mb-3` }>
            <h1>Produits nécessaires</h1>
            <div>{maintenanceCard.agentlist.length} produits</div>
          </div>
        </div> 

        <div className="row d-flex gx-2"> 
            {maintenanceCard.agentlist.map((agent) => ( 
            <div key= {agent.id} className={`col-12 col-md-6 col-lg-6 mb-3` }>
                <div className="fiche d-flex justify-content-between align-items-center pt-2 pb-2 px-3 h-100">
                  <div  className="col-2">
                  <Icon className="" src={agent.pict} alt={agent.name}/>
                  </div>
                  <div className="col d-flex justify-content-between ">
                    <span>{agent.name}</span>
                    <span className="mr-2">{agent.volume}</span>
                  </div>
                </div> 
            </div>
            ))} 
          </div>
         


        <div className={`d-flex align-items-center justify-content-center col-12 col-md-4 col-lg-4 ${isLargeScreen ? '' : ''}`}>

        <button variant="secondary"
                className="mt-3 btn btn-primary btn-block mx-auto" onClick={handleShow}>
                    Commencer
        </button>
        </div> 

        {/*popup maintenance step*/}
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <div className="col d-flex justify-content-between align-items-center">
              {/* Bouton Previous */}
              <Icon
                  className="icone-center" 
                  src={'circle-left.svg'}
                  alt="Previous"
                  onClick={() => sliderRef.current.slickPrev()}
                  style={{ cursor: 'pointer' }} // Ajoutez cette ligne pour changer le curseur
              />
                {/* Bouton Next */}
                {/* Numéro de la diapositive actuelle / Nombre total de diapositives */}
                  <div>{currentSlideIndex + 1} / {maintenanceCard.agentlist.length+1}</div>
              <Icon
                  src={'circle-right.svg'}
                  className="icone-center" 
                  alt="Next"
                  onClick={() => sliderRef.current.slickNext()}
                  style={{ cursor: 'pointer' }} // Ajoutez cette ligne pour changer le curseur
              />
            </div>
          </Modal.Header>
          <Modal.Body>
          <Slider ref={sliderRef} {...settings} >
                      {maintenanceCard.steplist
                      .slice() // Créez une copie du tableau pour éviter de modifier l'original
                      .sort((a, b) => a.steporder - b.steporder) // Triez selon l'ordre 
                      .map((step) => ( 
                      <div key= {step.id}>
                        <div>
                          <div className="d-flex align-items-center mb-4">
                            <div className="text-center">
                            <h1 className="order-circle">{step.steporder}</h1>
                            </div>
                            <div className="ms-3 ps-3">
                            <h1>{step.title}</h1>
                            </div>
                          </div>
                          {step.video && step.video.trim() !== "" && (
                            <div className="mb-4" align="justify">
                              <video width="100%" controls>
                                <source src={step.video} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                          <div className="mb-4" align="justify">{step.detail}</div>
                          <div>
                          <div className="row ">
                            {step.pict.split(';').map((imageLink, index) => (
                              <div key={index} className="col-12 col-md-6 col-lg-6 mb-3">
                                <img src={imageLink} alt={`Step ${step.title} - ${index + 1}`} className="step-image mb-3" loading="lazy"/>
                              </div>
                            ))}
                          </div>
                          </div>
                        </div>
                      </div>
                      ))} 
                      {/* resume slide at the end */}
                        <div className="d-flex align-items-center justify-content-center px-2">
                          {/* resume slide  */}
                          <div className="text-center">
                            <Icon 
                              className="icone-center mb-4" 
                              src={'trophy.svg'} 
                              alt="Trophy Icon" />
                            <h1 className="mb-3 text-center">Bravo, vous êtes dans les 5% les plus actifs</h1>
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</p>
                            <Icon 
                              className="icone-center mt-4 mb-4" 
                              src={'award.svg'} 
                              alt="Clock Icon" />
                            <p align="justify">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas. Sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</p>
                            <div className="text-center">
                            <div className={`col-12 col-md-12 col-lg-12 mx-auto ${isLargeScreen ? 'pr-0' : 'pr-2'}`}>
                              <button variant="secondary"
                                      className="mt-3 btn btn-primary btn-block mx-auto" onClick={handleShowPlanning}>
                                          Je planifie mon prochain entretien
                              </button>
                              </div> 
                              <div className={`col-12 col-md-12 col-lg-12 mx-auto ${isLargeScreen ? 'pr-0' : 'pr-2'}`}>
                              <button variant="secondary"
                                      className="mt-3 btn btn-primary btn-block mx-auto" onClick={handleShowFinish}>
                                          Je termine cet entretien
                              </button>
                              </div> 
                              </div>
                          </div>
                        </div>
              </Slider>
              <br/> <br/> <br/>
          </Modal.Body>
        </Modal>          
        

        {/* popup maintenance planner*/}
        <Modal show={showModalPlanning} onHide={handleClose} className="modal-maintenance px-2" activeclassname="active">
          <Modal.Header closeButton>
            <div className="text-center">
            <h1 >Planifier une date pour " {maintenanceCard.title.toLowerCase()} "</h1>
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
          <div className={`col-12 col-md-6 col-lg-4 mb-3 ${isLargeScreen ? 'px-3' : 'px-3'}`} > 
            <h1 mb-3>Sélectionnez une date</h1>
              <Calendar className="mt-3"
                onChange={handleDateChange}
                value={selectedDate}
                locale="fr-FR" // Utilisez la notation BCP 47 pour la langue française
              />
            </div>
            <div className={`col-12 col-md-6 col-lg-6 mb-3 ${isLargeScreen ? 'px-3' : 'px-3'}`} > 
              {selectedDate && (
                <div>
                   <h1>Date sélectionnée :</h1>
                    <p  className="mt-2 " >{`${selectedDate.getDate()} - ${selectedDate.toLocaleDateString('fr-FR', { month: 'long' })} - ${selectedDate.getFullYear()}`}</p>
                    <div>
                      <span  className="mt-2 mb-2" style={{ display: 'block' }}>Ajoutez une note</span>
                      <textarea
                        className="editable-content mb-2 mt-0"
                        style={{
                            width: `${isLargeScreen ? '50%' : '100%'}`,
                            minHeight: '100px',
                            padding: '8px',
                            border: '1px solid var(--color-secondary)',
                            borderRadius: '4px',
                            direction: 'ltr'
                        }}
                        value={textInput}
                        onChange={handleInputChange}
                    />
                      </div>
                    <div  className="col-6 mt-2">
                    <button variant="secondary"
                      className="btn btn-primary btn-block px-auto" onClick={() => handleSaveEvent(selectedDate)}>
                      Valider
                    </button>
                    </div>
                  </div>
                )}
                </div>
            </div>
              <br/> <br/> <br/>
          </Modal.Body>
        </Modal> 



        <br/> <br/> <br/> <br/>
        <br/>
    </div>
  );
};

export default MaintenanceCardDetail;