import React from 'react';

const WaitingModal = () => {
  return (
    <div className="modal-overlay" style={modalOverlayStyle}>
      <div className="modal" style={modalStyle}>
        <div className="modal-content" style={modalContentStyle}>
          <div className="modal-body" style={modalBodyStyle}>
            <div style={loaderStyle}></div>
            <p style={loadingTextStyle}>Attendez s'il vous plaît...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Styles inline pour le modal et son contenu
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const modalStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '20px'
};

const modalContentStyle = {
  maxWidth: '400px'
};

const modalBodyStyle = {
  display: 'flex',
  alignItems: 'center'
};

const loaderStyle = {
  border: '8px solid #f3f3f3',
  borderTop: '8px solid #3498db',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  animation: 'spin 1s linear infinite',
  marginRight: '20px'
};

const loadingTextStyle = {
  fontSize: '64px', // Taille de police plus grande
  color: 'red', // Couleur rouge
  textAlign: 'center' // Centrage horizontal du texte
};

export default WaitingModal;
