import React, {useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { useLocation } from 'react-router-dom';
import { Button } from "react-bootstrap"; 


import { getGeevCateg} from "../../services/glossary.service";

const Give = () => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    const { replaceproduct } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
    console.log("appareil reçu ",replaceproduct);

  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  const handleClicktoGive = (replaceproduct) => {
    const categ=getGeevCateg(replaceproduct.prod_name);
    window.open('https://www.geev.com/fr/recherche/objets?categories='+categ, '_blank');
  };

  const handleClicktoEcosystem = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    //?product=Aspirateur&location=Paris%252C%2BFrance&lat=48.856614&lon=2.3522219
    const location ='location=Paris%252C%2BFrance&lat=48.856614&lon=2.3522219';
    const categ= 'product='+replaceproduct.prod_name;
    window.open(' https://www.ecosystem.eco/donner-recycler/?'+categ+'&'+location, '_blank');
  };

  
  return (
    <div className="product-container justify-content-center"> 
         <div className="col-12 mx-auto"> 
          <h1 className="text-left mt-3">Je donne à un particulier</h1>
        </div> 
        <div className="col-12 card mt-3">
            <h1 className="text-left mb-3">Geev</h1>
            <span className="text-left mb-3">Ut fugiat ut sunt quia veniam. Voluptate perferendis perspiciatis quod nisi et. Placeat debitis quia recusandae odit et consequatur voluptatem.</span>
            <Button 
                    onClick={() => handleClicktoGive(replaceproduct)}
                >Je donne avec Geev</Button>
        </div>
    
        <div className="col-12 mt-3 mx-auto"> 
          <h1 className="text-left mt-3">Je donne à un point de collecte</h1>
        </div>
        <div className="col-12 card mt-3"> 
            <h1 className="text-left mb-2">Ecosystem</h1>
            <span className="text-left mb-3">Ut fugiat ut sunt quia veniam. Voluptate perferendis perspiciatis quod nisi et. Placeat debitis quia recusandae odit et consequatur voluptatem.</span>
                <Button 
                    className="mt-2"
                    onClick={() => handleClicktoEcosystem(replaceproduct)}
                >Je donne avec Ecosystem</Button>      
        </div>
        <br/>
        <br/>
        <br/>
      </div>
  );
};

export default Give;