import React, { Component} from "react";
import {Routes,Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
//import PWAInstallerPrompt from './services/pwainstaller'

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Welcome from "./components/welcome";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardFreemium from "./components/board-freemium.component";
import BoardAssure from "./components/board-assure.component";

//parcours entretien
import ProductList from "./components/maintenance/productlist";
import MaintenanceCard from "./components/maintenance/maintenancecard";
import MaintenanceCardDetail from "./components/maintenance/maintenancecarddetail";

//calendrier
import ViewCalendar from "./components/calendar/viewcalendar";

//calendrier
import SmartFix from "./components/smartfix/smartform";

//calendrier
import ViewNotif from "./components/notification/viewnotif";

//appliance
import ApplianceList from "./components/portfolio/appliancelist";
import ApplianceDetail from "./components/portfolio/appliancedetail";
import ApplianceAdd from "./components/portfolio/applianceadd";
import AppSearch from "./components/portfolio/appsearch";


//replace: entre for second life or renew
import ApplianceReplace from "./components/replaceappliance";
import ProductReplace from "./components/replaceproduct";

//secondlife
import ActionSelect from "./components/secondlife/actionselect";
import Give from "./components/secondlife/give";
import Sell from "./components/secondlife/sell";
import Recycle from "./components/secondlife/recycle";

//renew
import OptionSelect from "./components/renew/optionselect";
import CircularTip from "./components/renew/circulartip";
import FundSearch from "./components/renew/fundsearch";
import RenewOffer from "./components/renew/renewoffer";



import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showFreemiumBoard: false,
      showAssureBoard: false,
      currentUser: undefined,
      /*
      deferredPrompt: null,
      isSafari: false*/
    };
  }




  componentDidMount() {

    //capture global error
    window.onerror = (message, source, lineno, colno, error) => {
    console.log('Global error captured:', message);
    //this.logOut();
    //this.props.router.navigate("/login");
    //window.location.reload();
      // Effectuez votre logique de déconnexion et de redirection ici
      // par exemple : this.props.history.push('/login');
    };
    /*
    // Ajoutez un écouteur d'événement beforeinstallprompt lors du montage du composant
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
      const testsafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && navigator.standalone !== undefined;
    //const testSafari = /iPad|iPhone|iPod/.test(navigator.platform) && !window.MSStream;
    
    this.setState({
      isSafari: false
    });
    */
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showFreemiumBoard: user.roles.includes("ROLE_FREEMIUM"),
        showAssureBoard: user.roles.includes("ROLE_ASSURE"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }


  componentWillUnmount() {


    // Nettoyez l'écouteur d'événement lors du démontage du composant
    //window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showFreemiumBoard: false,
      showAssureBoard: false,
      currentUser: undefined,
    });
  }

/*
  handleBeforeInstallPrompt = (event) => {
    // Storing the event to show the install prompt later
    event.preventDefault();
    this.setState({ deferredPrompt: event });
  };

  handleInstallClick = () => {
    const { deferredPrompt } = this.state;
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }

        // Clear the deferredPrompt
        this.setState({ deferredPrompt: null });
      });
    }
  };
*/
  render() {

/*
<PWAInstallerPrompt 
                  render={({ onClick }) => (
                    <div class="col d-flex justify-content-center banner">
                    <p class="bannertext"> Installez notre application : </p>
                    <button variant="secondary"
                    className="btn button-install btn-block" onClick={onClick}>
                      Installer
                    </button>
                    </div>
                  )}
                  callback={(data) => console.log(data)} 
              />  
              
{this.state.isSafari ? (
          <div className="col d-flex justify-content-center banner">
            <p className="bannertext">Instructions pour installer sur Safari :</p>
            <ul>
              <li>Ouvrez le menu de partage <img src={require('./shareicon.png')}/></li>
              <li>Aller dans la liste en bas</li>
              <li>Sélectionnez "Sur l'écran d'accueil".</li>
              <li>Appuyez sur "Ajouter".</li>
            </ul>
          </div>
        ) : (
          <div>
              <div className="col d-flex align-items-center justify-content-center banner" >
                <span className="bannertext" > Installez notre application : </span>
                  <button className="button-install" onClick={this.handleInstallClick} disabled={!this.state.deferredPrompt}>
                  Installer
                </button>
              </div>
            </div>
        )}

                <Header/>
        <Navigation />

        <div className="container mt-3 app-container container-fluid">
       
*/


    return (
      <div>

        {this.state.currentUser ? ( 
          <div/>
          ):( 
            <div/>
          )}
        <div className="app-container container-fluid">
          <Routes>
            <Route path="" element={<Welcome />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />

            {/* Parcours entretien*/}
            <Route path="/entretien/fiche" element={<MaintenanceCard />} />  
            <Route path="/entretien/fichedetail" element={<MaintenanceCardDetail />} />  
            <Route path="/productlist" element={<ProductList />}/>
             
            {/* Calendrier*/}
            <Route path="/calendar" element={<ViewCalendar />} />

            {/* Notification*/}
            <Route path="/notification" element={<ViewNotif />} />


            {/* Inventaire */}
            <Route path="/inventaire" element={<ApplianceList />} />
            <Route path="/inventaire/fichedetail" element={<ApplianceDetail />} />
            <Route path="/inventaire/nouveau_2" element={<ApplianceAdd />} />
            <Route path="/inventaire/search" element={<AppSearch />} />


            {/* Replace */}
            <Route path="/replace/appliance" element={<ApplianceReplace />} />
            <Route path="/replace/product" element={<ProductReplace />} />

            {/* Secondlife */}
            <Route path="/secondlife/actionselect" element={<ActionSelect />} />
            <Route path="/secondlife/give" element={<Give />} />
            <Route path="/secondlife/sell" element={<Sell />} />
            <Route path="/secondlife/recycle" element={<Recycle />} />

            {/* Renew */}
            <Route path="/renew/optionselect" element={<OptionSelect />} />
            <Route path="/renew/fundsearch" element={<FundSearch />} />
            <Route path="/renew/tip" element={<CircularTip />} />
            <Route path="/renew/offer" element={<RenewOffer />}/>
            
            {/* SmartFix */}
            <Route path="/smarfix" element={<SmartFix />} />
            
        {/* Ajouter une route imbriquée pour les cartes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/freemium" element={<BoardFreemium />} />
            <Route path="/assure" element={<BoardAssure />} />
          </Routes>
          <div>
            <br/><br/>
          </div>
        </div>
        {<AuthVerify logOut={this.logOut}/>}
      </div>
    );
  }
}

export default App;