import axios from 'axios';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import authHeader from './auth-header';

const API_URL = `${config.serverIP}/api/ai/appliance/searchandsave`;

class SearchService {
   async searchAndSaveByImage(urlImage,searchText,userid) {
        try {
            const request={
                "urlImage" : urlImage,
                "searchText" : searchText,
                "userId" : userid
            }
            console.log('request  :', request);
            const response = await axios.post(API_URL,request,{ headers: authHeader() });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
}


// Créez une instance 
const SearchServiceInstance = new SearchService();

// Exportez l'instance en tant qu'export par défaut
export default SearchServiceInstance;

//export default new SearchService();