import axios from 'axios';
import authHeader from './auth-header';

import config from './config';

const API_URL = `${config.serverIP}/api/productgen`;


class RefproductService {
  getAllProduct() {
    return axios.get(API_URL,{ headers: authHeader() });
  }
}


// Créez une instance 
const RefproductServiceInstance = new RefproductService();

// Exportez l'instance en tant qu'export par défaut
export default RefproductServiceInstance;


//export default new RefproductService();