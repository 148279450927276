import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation } from 'react-router-dom';
import Icon  from "../common/Icon";
import ImageUploader  from "../common/ImageUploader";
import { Button ,Modal} from "react-bootstrap"; 
import ImagePopup from "../common/ImagePopup"; 
import MiniFunction from "../common/MiniFunction";

import ApplianceService from "../../services/appliance.service";

const ApplianceDetail = () => {
  const location = useLocation();
  const { state } = location|| {};
  const { appliance } = state|| {};
  //etat pour les popups
  const [factureIsOpen, setFactureIsOpen] = useState(false);
  const [noticeIsOpen, setNoticeIsOpen] = useState(false);
 
  
  console.log("appareil reçu ",appliance);

  const [apDetail, setApDetail] = useState([]);

  const toggleFacture = () => {
    console.log("Clicked! facture");
    setFactureIsOpen(!factureIsOpen);
  };

  const toggleNotice = () => {
    console.log("Clicked! Notice");
    setNoticeIsOpen(!noticeIsOpen);
  };

  useEffect(() => {
    
    console.log('Dans détail des produits');
    document.body.style.backgroundColor = '#ffffff';

    console.log('Dans inventaire des produits');
   
    ApplianceService.getApplianceDetail(appliance.id)
      .then((response) => {
        setApDetail(response.data);
         console.log('Détail du produit ',apDetail);
      })
      .catch((error) => {
        console.error('Une erreur s\'est produite:', error);
      });

  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial


  return (
       <div className="justify-content-center">

            <Modal show={factureIsOpen} onHide={toggleFacture} centered>
                <Modal.Header closeButton>
                <Modal.Title><h1 className="mb-2 mt-2">Facture</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                     {apDetail && !apDetail.facture ? (
                        <>
                            <p className="mb-2">Pas de facture renseignée</p>
                            <ImageUploader uploadImageFunction={ApplianceService.uploadFacture} />
                        </>
                        ) : (
                        <ImagePopup
                        src={apDetail.facture}
                        alt="Facture"
                        uploadImageFunction={ApplianceService.uploadFacture}
                        />
                    )}
                </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        
            <Modal show={noticeIsOpen} onHide={toggleNotice} centered>
                <Modal.Header closeButton>
                <Modal.Title><h1 className="mb-2 mt-2">Notice d'utilisation</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                     {apDetail && !apDetail.notice ? (
                        <>
                            <p className="mb-2">Pas de notice trouvée</p>
                            <ImageUploader uploadImageFunction={ApplianceService.uploadFacture} />
                        </>
                        ) : (
                        <div style={{ height: '500px', width: '100%' }}>
                            <iframe title="PDF Viewer" src={apDetail.notice} style={{ height: '100%', width: '100%' }}></iframe>
                        </div>
                    )}
                </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <div className="row d-flex relative-container">   
                    <div className="violet-background"></div>
                    <div className="col-8 image-container">
                        <img src={apDetail.pict} alt={apDetail.prod_name} className="image-content" />
                    </div>
                    <div className="col-6 product-info">
                        <h4 className="mt-2 fw-bold">{apDetail.prod_name}</h4>
                        <h4 className="mt-1">{apDetail.name}</h4>
                        {apDetail.classenergie && ( // Vérifie si apDetail.classenergie existe
                            <p className="mt-1">Classe énergétique : {apDetail.classenergie}</p>
                        )}
                    </div>
            </div>
            
            <h4 className="mt-4 mb-0 text-center fw-bold " >Description</h4>
            <div className="mt-0 row g-0 d-flex relative-container px-3 pt-4">   
                   <div className="secondary-border"></div>
                    <p className="mt-3 mb-1">Enregistré le {new Date(apDetail.dt_creation).toLocaleDateString()}</p>
                    <p className="mb-1">Date d'achat :  {apDetail.dt_achat ? new Date(apDetail.dt_achat).toLocaleDateString() : 'Inconnu'}</p>
                    <p align="justify">{apDetail.note}</p>
                    <div className="mb-3 col-4 col-md-3 col-lg-3"> 
                    <Button className="btn-primary">Modifier</Button>
                    </div>    
            </div>                
            <div>

                           



                <h4 className="mt-4 text-center fw-bold">Voir les pièces jointes</h4>
                
                <div className="row mt-2 d-flex g-2"> 
                    <div className="col-4 col-md-3 col-lg-3"> 
                        <div className="card-style-white">
                            <Icon src="book-open.svg" className="icon"/>
                            <p>Entretien</p>
                        </div>
                    </div>

                    <div className="col-4 col-md-3 col-lg-3" onClick={() => toggleFacture()}> 
                        <div className="card-style-white">
                            <Icon src="invoice.svg" className="icon"/>
                            <p>Facture</p>
                        </div>
                    </div>

                    <div className="col-4 col-md-3 col-lg-3" onClick={() => toggleNotice()}> 
                        <div className="card-style-red">
                            <Icon src="book-open.svg" className="icone-blanc"/>
                            <p>Notice</p>
                        </div>
                    </div>

                </div>

              
            </div>



            <h4 className="mt-4 text-center fw-bold">Découvrir les services</h4>
            
            <div className="row mt-2 g-2"> 
                <div className="col-3 col-md-3 col-lg-2"> 
                    <MiniFunction
                            image="spray-can-sparkles.svg" 
                            text="Entretenir"
                    />
                </div>

                <div className="col-3 col-md-3 col-lg-2"> 
                    <MiniFunction
                            image="wrench.svg" 
                            text="Réparer"
                    />
                </div>

                <div className="col-3 col-md-3 col-lg-2"> 
                    <MiniFunction
                            image="hand-holding-heart.svg" 
                            text="Seconde Vie"
                    />
                </div>

                <div className="col-3 col-md-3 col-lg-2"> 
                    <MiniFunction
                            image="puzzle-piece.svg" 
                            text="Pièces détachées"
                    />
                </div>

            </div>


        <div className="mb-3 mt-5 row">
          <div className="col-12 col-md-4 col-lg-4"> 
              <Button className="mb-2 mt-2">
                Retour à l'inventaire
              </Button>
          </div>
        </div> 
          
          
   
              
        <br/>
        <br/>
        <br/>
    </div>
  );
};

export default ApplianceDetail;