import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'; // Si tu utilises react-bootstrap pour le modal

import getLabel from "../../services/glossary.service";

import NotifService from "../../services/notif.service";

import Navigation from "../navigation";

import { Navigate, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faCircle,faMountainSun } from '@fortawesome/free-solid-svg-icons';
import "../../css/notif.css";

const ViewNotif = () => {

  const [notifs, setNotifs] = useState(null);

  const navigate = useNavigate();


  const [showModal, setShowModal] = useState(false);

  // Fonction pour gérer l'ouverture du modal et la mise à jour du statut
  const handleNotificationClick = (id) => {
    // Mettre à jour le statut de la notification
    //updateNotificationStatus(id, 'READ');
    // Ouvrir le modal
    setShowModal(true);
  };

  // Fonction pour fermer le modal
  const handleCloseModal = () => setShowModal(false);


  /*
  const events = [
    { date: '2024-02-10', action: 'Réunion' },
    { date: '2024-02-15', action: 'Formation' },
    // Ajoutez d'autres événements avec des dates et des actions
  ];
  */
  useEffect(() => {
    NotifService.getNotif().then(
      (response) => {
        setNotifs(response.data);
       console.log('les notifs récupérées:', response.data);
  
      },
      error => {
        // Gérer les erreurs
      }
    );
  }, []);


  return (
    <div className=" mt-3">
          <div className="container">
            <Navigation/>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                &lt;
              </button>
              <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Mes Notifications</h3>
            </div>
          </div>
        {notifs && notifs.length > 0 && ( 
         <div className="mt-4">
            
            {notifs.map((notif) => (
              
              <div 
                onClick={() => handleNotificationClick(notif.id)} 
                key={notif.id} 
                className={`mb-2 ${notif.status === 'ACTIF' ? 'read-notification' : 'unread-notification'}`}
                style={{  borderTop: "1px solid lightgray",   // Add top border
                  borderBottom: "1px solid lightgray" // Add bottom border"
                }}>
                <div className="mt-1 mb-1 container" >
                    {/* Première ligne: point rouge si le statut est 'ACTIF', aligné à droite */}
                    <div className="mt-2 d-flex justify-content-end align-items-center mb-2">
                      {notif.status === 'ACTIF' && (
                      <div
                        style={{
                          width: '0.5rem',
                          height: '0.5rem',
                          backgroundColor: '#C03744',
                          borderRadius: '50%',
                        }}
                      ></div>)}
                    </div>
                  
                    {/* Deuxième ligne: icône à gauche, et colonne du titre, date et détail */}
                    <div className="d-flex align-items-center" style={{ position: 'relative', display: 'flex' }}>
                      {/* Colonne de l'icône à gauche */}
                      <div className="icon-column">
                        {notif.type === 'MAINTENANCE' && 
                          <div className={`card-caroussel ${notif.status === 'ACTIF' ? 'white-background' : 'light-green-background'}`}>
                              <img 
                                src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                                style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                          </div>
                        }
                        {notif.type === 'CHALLENGE' && 
                          <div className={`card-caroussel ${notif.status === 'ACTIF' ? 'white-background' : 'light-green-background'}`}>
                            <img 
                              src={process.env.PUBLIC_URL + '/icons/Heart.svg'} 
                              style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                          </div>
                        }
                      </div>
                  
                      {/* Colonne du titre, date et détail */}
                      <div className="ms-3 d-flex flex-column justify-content-center flex-grow-1">
                        <div className="d-flex justify-content-between">
                          {/* Titre */}
                          <span style={{ fontSize: '0.75rem', fontWeight: '600', textTransform: 'uppercase'}}>
                            {getLabel(notif.type)}
                          </span>
                  
                          {/* Date alignée à droite */}
                          <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                            {new Date(notif.event.dt_realisation).toLocaleDateString()}
                          </span>
                        </div>
                  
                        <span className="mt-2" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                          {notif.title.charAt(0).toUpperCase() + notif.title.slice(1).toLowerCase()}
                        </span>
                      </div>
                    </div>

                    {/* Modal */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Détail de la notification</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>{notif.title}</p>
                        <p>Voici plus d'informations sur la notification...</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Fermer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                 
                  
                    <br />
                  </div>
                  </div>
            ))}
          
          <div><br/><br/><br/><br/></div>
          </div>
      )}
    </div>
  );
};

export default ViewNotif;