import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../../css/calendar.css";
import { IconByType }  from "../common/Icon";
import getLabel, { capitalizeFirstLetter} from "../../services/glossary.service";

import planningService from "../../services/planning.service";


const ViewCalendar = () => {

  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState(null);

  
  /*
  const events = [
    { date: '2024-02-10', action: 'Réunion' },
    { date: '2024-02-15', action: 'Formation' },
    // Ajoutez d'autres événements avec des dates et des actions
  ];
  */
  useEffect(() => {
    planningService.getPlanning().then(
      (response) => {
        setEvents(response.data);
       console.log('les events récupérées:', response.data);
  
      },
      error => {
        // Gérer les erreurs
      }
    );
  }, []);


  const tileClassName = ({ date, view }) => {

    if (events) { 
      if (view === 'month') {
    
        // Formatage de la date de chaque événement
        const eventDates = events.map((event) => {
          const date = new Date(event.dt_realisation);
          return date.toLocaleDateString();
        });
    
        // Formatage de la date actuelle
        const formattedDate = date.toLocaleDateString();
    
        // Vérification de la présence de la date actuelle dans les dates des événements
        return eventDates.includes(formattedDate) ? 'event-dot' : null;
      }
    }
    
    return null;
  };

  return (
    <div className="row gx-0 calendar-container">
      <div className="col-12 col-md-8 col-lg-12 mb-3 mt-3" > 
        <h1>Calendrier</h1>
        <div className="underline mb-4"></div>
        <Calendar className="mt-3"
          onChange={setDate}
          value={date}
          tileClassName={tileClassName}
        />
        </div>
        {events && events.length > 0 && (
         <div className="col-12 mb-3 mt-3" > 
         <h1 className="mb-1">Vos évènements</h1>
         <div className="underline mb-4"></div>
          <div>
            {events.map((event) => (
              <span key={event.id}>
                <div className="rectangle-style-white mb-3 d-flex">
                <div className="col-2 text-center">
                {IconByType(getLabel(event.type))}
                </div>
                <div>
                  <strong> {getLabel(event.type)}</strong><br />
                  {new Date(event.dt_realisation).toLocaleDateString()}<br />
                  <strong></strong> {capitalizeFirstLetter(event.title)}
                </div>
                </div>
              </span>
            ))}
           
          </div>
          <br/><br/>
          </div>
         
      )}
       
        

        
    </div>
  );
};

export default ViewCalendar;