import React, { useState } from 'react';

const SwitchButton = ({ leftLabel, rightLabel, onChange}) => {
  const [leftChecked, setLeftChecked] = useState(true);

  const handleSwitch = (selectedSwitch) => {
    const isLeft = selectedSwitch === 'left';
    if (selectedSwitch === 'left') {
      setLeftChecked(true);
    } else if (selectedSwitch === 'right') {
      setLeftChecked(false);
    }
    onChange?.(isLeft ? leftLabel : rightLabel);
  };

  return (
    <div className="mt-4 mb-4 d-flex justify-content-between align-items-center custom-switch-container">
      <label className={`custom-switch ${leftChecked ? 'checked' : ''}`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id="left-switch"
          checked={leftChecked}
          onChange={() => handleSwitch('left')}
        />
        <span className="custom-control-indicator"></span>
        <span className="custom-control-description">{leftLabel}</span>
      </label>

      <label className={`custom-switch ${!leftChecked ? 'checked' : ''}`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id="right-switch"
          checked={!leftChecked}
          onChange={() => handleSwitch('right')}
        />
        <span className="custom-control-indicator"></span>
        <span className="custom-control-description">{rightLabel}</span>
      </label>
    </div>
  );
};

export default SwitchButton;
