import React from 'react';
import Icon  from "./Icon";
//style in App.css
const MiniFunction = ({ image,text }) => {
    const url=process.env.PUBLIC_URL +'/icons/'+image;
    console.log("url bagde : ",url);
    return (
        <div className='text-center function' >
            <div>
                <div  className='mx-auto function-carre'>
                      <Icon 
                        src={image} 
                        alt="Contenu" 
                        className=' function-image'/>
                
                    </div>
            <p>{text}</p>
            </div>
            
        </div>
    );
};

export default MiniFunction;
