import React, {useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { useLocation } from 'react-router-dom';
import { Button } from "react-bootstrap"; 
import { useNavigate } from 'react-router-dom';

const ActionSelect = () => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    const { replaceproduct } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
    console.log("appareil reçu ",replaceproduct);

   const navigate = useNavigate();
  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  const handleClicktoGive = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/give`,{ state: { replaceproduct } });
  };

  const handleClicktoSell = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/sell`,{ state: { replaceproduct } });
  };

  const handleClicktoRecycle = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/recycle`,{ state: { replaceproduct } });
  };
  
  return (
    <div className="product-container justify-content-center"> 
         <div className="col-12 mx-auto"> 
          <h1 className="text-left mt-3 mb-3">Deuxième vie : </h1>
          <h1 className="text-left mb-4">{replaceproduct.prod_name}</h1>
        </div> 
        <div className="col-12 mb-4">
            <span >Ut fugiat ut sunt quia veniam. Voluptate perferendis perspiciatis quod nisi et. Placeat debitis quia recusandae odit et consequatur voluptatem.</span>
            <div className="col-12 col-lg-6"> 
                <Button 
                    className="mt-2"
                    onClick={() => handleClicktoGive(replaceproduct)}
                >Je donne</Button>
            </div>
        </div>
        <div className="col-12 mt-4 mb-4"> 
            <span >Ut fugiat ut sunt quia veniam. Voluptate perferendis perspiciatis quod nisi et. Placeat debitis quia recusandae odit et consequatur voluptatem.</span>
            <div className="col-12 col-lg-6"> 
                <Button 
                    className="mt-2"
                    onClick={() => handleClicktoSell(replaceproduct)}
                >Je vends</Button>
            </div>
        </div>
        <div className="col-12 mt-4 mb-4"> 
            <span >Ut fugiat ut sunt quia veniam. Voluptate perferendis perspiciatis quod nisi et. Placeat debitis quia recusandae odit et consequatur voluptatem.</span>
            <div className="col-12 col-lg-6"> 
                <Button 
                    className="mt-2"
                    onClick={() => handleClicktoRecycle(replaceproduct)}
                >Je recycle</Button>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
  );
};

export default ActionSelect;