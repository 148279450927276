import React from 'react';
import { Link } from 'react-router-dom';
//import InstallButton from "./InstallButton";
//import 'bootstrap/dist/css/bootstrap.min.css'; // Importez le fichier CSS de Bootstrap


const Header = () => {
  console.log("Show header");
  return (
    <div className="mt-2 header d-md-none"> {/* Applique la classe 'd-md-none' pour cacher sur les écrans de taille moyenne et supérieure */}
    <div className="mb-2 container">
    <div className="mt-2 row align-items-center">
      <div className="col">
          <img src={process.env.PUBLIC_URL + '/icons/Logo_dcare.svg'} style={{ width: 'auto', height: '20px', verticalAlign: 'middle' }}/>
        </div>  
      <div className="col d-flex justify-content-end">
      <span 
        style={{marginRight : '5px'}}>
      </span>
            <Link to="/profile">
          <img src={process.env.PUBLIC_URL + '/icons/Profile.svg'} style={{ width: 'auto', height: '20px', verticalAlign: 'middle' }} alt="profil" />
          </Link>
         </div>
        
      </div>
    </div>
  </div>
  );
};

export default Header;
