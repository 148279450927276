import React, { Component } from "react";
//import { Nav, NavItem} from 'reactstrap';
import { Navbar, NavDropdown, Nav, Container,Modal,Badge } from 'react-bootstrap';


import { NavLink,Link } from 'react-router-dom';

import "../css/navigation.css";

import AuthService from "../services/auth.service";

import NotifService from "../services/notif.service";

import EventBus from "../common/EventBus";
import Icon  from "./common/Icon";

export default class Navigation extends Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
          currentUser: undefined,
          showDropdown: false,
          nbNotifications: 0,
        };
      }

      // Fonction pour mettre à jour le badge en utilisant le service worker
      updateBadge = (nbNotif) => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(registration => {
            registration.active.postMessage({
              action: 'updateBadge',
              badgeCount: nbNotif,
            });
          });
        }
      };


      loadNotifications = async () => {
        try {
          const nbNotif = await NotifService.getNbNotif();
          // Mettez à jour le badge en appelant la fonction
          this.updateBadge(nbNotif.data);
          console.log('*******************nbNotif :', nbNotif);
          this.setState({ nbNotifications: nbNotif.data });
        } catch (error) {
          console.error('Erreur lors du chargement des notifications :', error);
        }
      };

      handleToggleModal = () => {
        this.setState((prevState) => ({
          showModal: !prevState.showModal,
        }));
      };

      handleMouseEnterDropdown = () => {
        this.setState({ showDropdown: true });
      };

      handleMouseLeaveDropdown = () => {
        this.setState({ showDropdown: false });
      };

      componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
          this.setState({
            currentUser: user
          });
          this.loadNotifications();
        }
         EventBus.on("logout", () => {
          this.logOut();
        });
      }
    
      componentWillUnmount() {
        EventBus.remove("logout");
      }
    
      logOut= async () => {
        console.log('*******************logout begin:');
        await AuthService.logout();
        this.setState({
          currentUser: undefined
        });
        console.log('*******************logout succes:');
      }

/*
 {
                          tabs.map((tab, index) =>(
                              <Nav.Item key={`tab-${index}`}>
                                  <NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                                      <div className="row d-flex flex-column justify-content-center align-items-center">
                                      <FontAwesomeIcon size="lg" icon={tab.icon}/>
                                      <div className="bottom-tab-label">{tab.label}</div>
                                      </div>
                                  </NavLink>
                              </Nav.Item>
                          ))
                          }
*/

//d-none d-lg-block : top bar only showed for large screen 
//d-block d-lg-none : bottom bar only available for small scree 
    render ()
    {
        
      const nav = [{
        route: "/home",
        key: "Home",
        label: "Accueil",
        icon : "Home.svg"
      },{
        route: "/notification",
        key: "Notification",
        label: "Notification",
        icon : "Bell.svg"
      },{
        route: "/inventaire",
        key: "Inventaire",
        label: "Inventaire",
        icon : "Wallet.svg"
      },{
        route: "/calendar",
        key: "Calendrier",
        label: "Calendrier",
        icon : "Calendar.svg"
      }];


        const dropdown = [{
          route: "/profile",
          key: "Profile",
          label: "Profile"
        },{
          route: "/productlist",
          key: "Entretien",
          label: "Entretien"
        },{
          route: "/replace/appliance",
          key: "Second",
          label: "Seconde Vie"
        },{
          route: "/cgu",
          key: "CGU",
          label: "CGU"
        },{
          route: "/faq",
          key: "FAQ",
          label: "FAQ"
        },{
          route: "/smarfix",
          key: "SmartFix",
          label: "SmartFix"
        }];

        


        const { showDropdown } = this.state;
        const { showModal } = this.state;
        return (
            <div>
                <nav className="navbar-expand-md navbar-light d-none d-lg-block sticky-top top-tab-nav" role="navigation">
                    <Navbar collapseOnSelect expand="sm">
                    <Container>
                        <Navbar.Brand href="/">
                        <img src={process.env.PUBLIC_URL + '/icons/Logo_dcare.svg'} style={{ width: '100px', height: 'auto', verticalAlign: 'unset'}} alt="DCARE" />
                          </Navbar.Brand>
                        <Nav className="ml-auto">
                            {this.state.currentUser ? ( 
                                <div className="navbar-nav ml-auto">
                                   {nav.map((item) => (
                                    <Nav.Item key={item.key} className="nav-link" activeclassname="active">
                                       <NavLink to={item.route} className="nav-link" activeclassname="active">{item.label}</NavLink>
                                    </Nav.Item>
                                    ))}
                                    <NavDropdown title="Paramètres" id="nav-dropdown" className="nav-link custom-dropdown" activeclassname="active"
                                      show={showDropdown}
                                      onMouseEnter={this.handleMouseEnterDropdown}
                                      onMouseLeave={this.handleMouseLeaveDropdown}>
                                      {dropdown.map((item) => (
                                        <NavDropdown.Item key={item.key} as={NavLink} to={item.route} className="drop-link" activeclassname="active">
                                          <span>{item.label}</span>
                                        </NavDropdown.Item>
                                      ))}
                                      <NavDropdown.Item as={NavLink} to={"/login"} className="drop-link" activeclassname="active" onClick={this.logOut}>
                                        <span>Déconnecter</span>
                                      </NavDropdown.Item>
                                    </NavDropdown>
                                    
                                </div>
                                ) : (
                                <div className="navbar-nav ml-auto"> 
                                     {/* <Nav.Item key='register'>
                                        <NavLink to={"/register"} className="nav-link">Sign Up</NavLink>
                                    </Nav.Item>   */}
                                </div> 
                                )}
                        </Nav>
                    </Container>
                    </Navbar>
                </nav>
                {/* Bottom Tab Navigator
                <Icon src={item.icon}  className="icone-blanc"/>
                <FontAwesomeIcon size="2x" icon={item.icon} />
                <div className="navbar-expand-md navbar-light d-block d-lg-none sticky-top top-tab-nav"><Header/></div>
                */}
                {this.state.currentUser ? ( 
                  <div>
                  <nav className="fixed-bottom navbar-dark d-block d-lg-none bottom-tab-nav" role="navigation">
                      <div className=" d-flex flex-row justify-content-around w-100">
                        {nav.map((item) => (
                          <Nav.Item className="col" key={item.key}>
                            <NavLink to={item.route} className="nav-link bottom-nav-link" activeclassname="active">
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                <div className="bottom-tab-item" activeclassname="active">
                                {item.icon === "Chat.svg" && this.state.nbNotifications > 0 && (
                                  <Badge pill className="notification-badge">
                                    {this.state.nbNotifications}
                                  </Badge>
                                )}
                                 <Icon src={item.icon} className="icone-nav" activeclassname="active"/>
                                <br/>
                                  {item.label}
                                </div>
                                </div>
                            </NavLink>
                          </Nav.Item>
                        ))}
                        <Nav.Item className="col" key='parametre'>
                            <Link className="nav-link bottom-nav-link" activeclassname="active" onClick={this.handleToggleModal}>
                              <div className="row d-flex flex-column justify-content-center align-items-center">
                                <div className="bottom-tab-item">
                                <Icon src="Menu.svg" className="icone-nav" activeclassname="active"/>
                                <br/>
                                  Paramètres</div>
                              </div>
                            </Link>
                          </Nav.Item>
                        <Modal show={showModal} onHide={this.handleToggleModal} activeclassname="active" centered>
                          <Modal.Header closeButton>
                            <Modal.Title>Paramètres</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {dropdown.map((item) => (
                            <Nav.Link key={item.key} as={NavLink} className="modal-link" to={item.route} activeclassname="active" onClick={this.handleToggleModal}>
                              {item.label}
                            </Nav.Link>
                            ))}
                            <Nav.Link as={NavLink} to={"/login"} className="modal-link" activeclassname="active" onClick={this.logOut}>
                              Déconnecter
                            </Nav.Link>
                          </Modal.Body>
                        </Modal>
                      </div>
                  </nav>
                  </div>  
                  
                  ):( 
                    <div/>
                  )}
            </div>
    )
    }
}